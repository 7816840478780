import {Button, Card} from "react-bootstrap";
import DateFormat from "../../helpers/dates";

function Credential({data}) {
    return (
            <Card className={'text-center'} bg={'light'}>
                <Card.Img variant="top" src={data.logo.url + '?w=435'} alt={data.logo.title} />
                <Card.Body  >
                    <Card.Title>{data.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{data.type}</Card.Subtitle>
                </Card.Body>
                <Card.Body>
                    <Button variant="success" className={"stretched-link"} href={data.link} target={'_new'}>View Certification</Button>
                </Card.Body>
                <Card.Footer className="text-muted">
                    <DateFormat date={data.validFrom}/> - <DateFormat date={data.validTo}/>
                </Card.Footer>
            </Card>
    );
}

export default Credential;