import moment from "moment";

function DateFormat({date}) {

    if (!date) {
        return "Present"
    }
    return (
        moment(date, "YYYY-MM-DD").format("MMMM YYYY")
    )
}

export default DateFormat;