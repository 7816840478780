import {Container, Nav, Navbar} from "react-bootstrap";
import {useQuery} from "urql";

const NAV_QUERY = `
    query pageCollectionQuery {
      pageCollection(order: path_ASC) {
        items {
          title
          navigationTitle
          path
        }
      }
    }
`;

function Navigation() {

    const [result] = useQuery({
        query: NAV_QUERY,
    });

    const { data, fetching } = result;

    if (fetching) return "";

    return (
        <Navbar bg="light" expand="lg" fixed="top">
            <Container>
                <Navbar.Brand href="/">Martin Noble</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {data.pageCollection.items.map((item) => (
                            <Nav.Link href={item.path}>{item.navigationTitle ? item.navigationTitle : item.title}</Nav.Link>
                        ))}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    );
}

export default Navigation;