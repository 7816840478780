import {Container, Nav, Navbar} from "react-bootstrap";
import {useQuery} from "urql";

const NAV_QUERY = `
query contactsCollectionQuery {
  contactsCollection(limit: 1) {
    items {
      sys {
        id
      }
      heading
      contactCollection {
        items {
          title
          link
        }
      }
    }
  }
}   
`;

function FooterNavigation() {

    const [result] = useQuery({
        query: NAV_QUERY,
    });

    const { data, fetching } = result;

    if (fetching) return "";

    const contactData = data.contactsCollection.items[0];
    console.log(data);
    return (
        <Navbar bg="light" fixed="bottom">
            <Container>
                <Nav className="justify-content-center flex-grow-1 pe-3">
                    {contactData.contactCollection.items.map((item) => (
                        <Nav.Link key={item.link} href={item.link} target={"_blank"}>{item.title}</Nav.Link>
                    ))}
                </Nav>
            </Container>
        </Navbar>

    );
}

export default FooterNavigation;