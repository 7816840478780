import {Button, Card, Col, Image, Modal, Row} from "react-bootstrap";
import {useState} from "react";

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.image.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Image fluid={true} src={props.image.url} alt={props.image.title}/>
            </Modal.Body>
        </Modal>
    );
}

function ImageGallery({images}) {

    const [modalShow, setModalShow] = useState(false);
    const [modalImage, setModalImage] = useState("");

    return (
        <>
            <MyVerticallyCenteredModal
                show={modalImage}
                image={modalImage}
                onHide={() => setModalImage(false)}
            />
            <Row xs={1} sm={1} md={2}  lg={3} className="g-4">
                {images.items.map((image) => (
                    <Col key={image.sys.id}>
                        <Card className={'text-center'} bg={'light'}>
                            <Card.Img variant="top" src={image.url + '?w=480'} alt={image.title} />
                            <Card.Body  >
                                <Card.Title>{image.title}</Card.Title>
                                <Card.Text>
                                    {image.description}
                                    <a className={"stretched-link"} href={"#"} onClick={() => setModalImage(image) } />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    );
}

export default ImageGallery;