import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createClient, Provider} from 'urql';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Page from "./components/page/Page";
import Error from "./components/error/Error";
import Navigation from "./components/navigation/Navigation";
import FooterNavigation from "./components/footer/FooterNav";
import { HelmetProvider } from 'react-helmet-async';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Page />,
        errorElement: <Error />,
    },
    {
        path: "/:child",
        loader: ({ params }) => {
            return params.child;
        },
        element: <Page />,
        errorElement: <Error />,
    },
]);



const client = createClient({
    url: 'https://graphql.contentful.com/content/v1/spaces/eab4xv6d7hdu/environments/master',
    fetchOptions: () => {
        return {
            headers: {Authorization: `Bearer Sox43E6pq0I-6unBY6IFz5KgI_zR9pa08q4T4aug6_s`},
        }
    },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <HelmetProvider>
        <Provider value={client}>
            <Navigation />
            <RouterProvider router={router} />
            <FooterNavigation />
        </Provider>
     </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
