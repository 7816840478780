import {Card} from "react-bootstrap";

function Contact({title, contacts}) {

    return (
        <Card className={'text-center'} bg={'light'}>
            <Card.Body  >
                <Card.Title>{title}</Card.Title>
                {contacts.items.map((contact) => (
                    <Card.Link key={contact.link} href={contact.link} target={'_new'}>{contact.title}</Card.Link>
                ))}
            </Card.Body>
        </Card>
    );

}

export default Contact;