import { INLINES } from '@contentful/rich-text-types'
import {useQuery} from "urql";
import { useLoaderData } from "react-router-dom";
import {Col, Container, Image, Row} from "react-bootstrap";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import Contact from "../contact/contact";
import Credentials from "../credentials/credentials";
import ImageGallery from "../gallery/ImageGallery";
import {Helmet} from "react-helmet-async";

const CONTENT_QUERY = `
    query pageCollectionQuery {
      pageCollection(where: { path: "/PAGE_PATH" }, limit: 1) {
        items {
          sys {
            id
          }
          title
          metaTitle
          bodyImage {
            url
            title
          }
          bodyContent {
            json
            links {
              assets {
                block {
                  sys {
                    id
                  }
                  url
                  title
                }
              }
            }
          }
          otherContentCollection(limit: 5) {
            items {
              ... on Contacts {
                heading
                contactCollection {
                  items {
                    title
                    link
                  }
                }
              }
              ... on Credentials {
                title
                certificationsCollection {
                  items {
                    title
                    type
                    validFrom
                    validTo
                    logo {
                        url
                        title
                    }
                    link
                  }
                }
              }
              ... on ImageGallery {
                title
                imageCollection {
                  items{
                    sys {
                      id
                    }
                    url
                    title
                    description
                  }
                }
              }
            }
          }
        }
      }
    }

`;

const options = {
    renderNode: {
        [INLINES.HYPERLINK]: (node, children) => {
            return <a target={node.data.uri.startsWith("/") ? '' : '_blank'} rel="noopener noreferrer" href={node.data.uri}>{children}</a>
        }
    }
}

function Page() {

    let path = useLoaderData();
    if (!path) {
        path = "";
    }
    console.log("Page path:", path);

    const finalContentQuery = CONTENT_QUERY.replace("PAGE_PATH", path);

    console.log(finalContentQuery);

    const [result] = useQuery({
        query: finalContentQuery,
    });

    const { data, fetching, error } = result;

    if (fetching) return;

    console.log("Result:", data, error);

    if (error || !data || !data.pageCollection || !data.pageCollection.items || data.pageCollection.items.length === 0) {
        throw new Response("Not Found", { status: 404 });
    }
    console.log(data);

    const pageData = data.pageCollection.items[0];



    const pageContent = pageData.bodyContent ? documentToReactComponents(pageData.bodyContent.json, options) : '';

    return (
        <Container className={'app-container'}>

            <Row className={"text-center"}>
                <Col>
                    <Helmet>
                        <title>{pageData.metaTitle ? pageData.metaTitle : pageData.title}</title>
                    </Helmet>
                    <h1>{pageData.title}</h1>
                </Col>
            </Row>
            <Row xs={1} lg={pageData.bodyImage ? 2 : 1}>
                {pageData.bodyImage &&
                    <Col className={'body-image'} >
                        <Image fluid={true} src={pageData.bodyImage.url} alt={pageData.bodyImage.title}/>
                    </Col>
                }
                {pageContent &&
                    <Col className={'body-content'}>
                        {pageContent}
                    </Col>
                }
            </Row>
            {pageData.otherContentCollection.items.map((item) => (
                <>
                    {item.__typename === "Contacts" &&
                        <Row>
                            <Contact title={item.heading} contacts={item.contactCollection}/>
                        </Row>
                    }
                    {item.__typename === "Credentials" &&
                        <Credentials creds={item.certificationsCollection}/>
                    }
                    {item.__typename === "ImageGallery" &&
                        <ImageGallery images={item.imageCollection}/>
                    }
                </>
            ))}
        </Container>
    );

}
export default Page;
