import Credential from "./credential";
import {Col, Row} from "react-bootstrap";


function Credentials({creds}) {

    return (
        <Row xs={1} sm={2} md={3}  lg={3} xl={4} className="g-4">
            {creds.items.map((cred) => (
                <Col key={cred.title}>
                    <Credential data={cred} />
                </Col>
            ))}
        </Row>
    );
}

export default Credentials;