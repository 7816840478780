import { useRouteError } from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";


function Error() {

    const error = useRouteError();

    return (
        <Container className={'text-center app-container'}>

            <Row>
                <Col>
                    <h1>Error: {error.status}</h1>
                </Col>
            </Row>

        </Container>
    );
}

export default Error;